// mobile
$mobile-max: 767px;
$mobile-small-max: 374px;

// tablet
$tablet-max: 1091px;
$tablet-min: 768px;

// desktop
$desktop-min: 1092px;

$tablet-height: 700px;

@mixin tabletH {
    @media (max-height: #{$tablet-height}) {
        @content;
    }
}

@mixin mobile {
    @media (max-width: #{$mobile-max}) {
        @content;
    }
}

@mixin mobile-small {
    @media (max-width: #{$mobile-small-max}) {
        @content;
    }
}
 
@mixin tablet {
    @media (min-width: #{$tablet-min}) and (max-width: #{$tablet-max}) {
        @content;
    }
}

@mixin mobileAndTablet {
    @include mobile { @content; }
    @include tablet { @content; }
}
 
@mixin desktop {
    @media (min-width: #{$desktop-min}) {
        @content;
    }
}