@import '../../assets/styles/colors.scss';
@import '../../assets/styles/mixins.scss';

@include mobile {
    .ride-details-page {
        height: 100% !important;
    }
}

.transfer-component {
    width: 460px;
    background: $white;
    margin-left: 82px;
    margin-top: -64px;
    padding: 32px !important;
    z-index: 9;
    position: relative;

    @include mobile {
        width: 100%;
        height: 100% !important;
        margin: 0;
        padding: 8px !important;
    }

    .title {
        font-size: 48px;
        line-height: 52px;
        letter-spacing: 0.02em;
        color: $text;
        margin-right: 56px;

        @include mobile {
            margin: 0;
        }

        @include tabletH {
            font-size: 36px;
            margin: 0 !important;
        }
    }

    .connect-division {
        height: 16px;
        border-left: dotted $easy-grey 3px;
        margin-left: 20px;

        @include mobile {
            height: 10px;
            min-height: 10px;
            border-left: dotted $easy-grey 2px;
        }
    }

    .hourly-booking {
        height: 40px;

        .ant-select {
            width: 107px;
            height: 40px;
            background: $lighter-grey;
            margin-left: 12px;

            .ant-select-selector {
                border: none;
                background: none;
                outline: none !important;
                box-shadow: none !important;
                height: 40px;
                display: flex;
                align-items: center;

                input {
                    font-size: 16px;
                    color: $dark-grey;
                }
            }
        }
    }
}

.distance-duration-label {
    position: absolute;
    bottom: 8rem;
    right: 20rem;
    background: $dark-grey;

    .distance-duration-label-content {
        padding: 15px;
        color: $white;

        .duration-text{
            color: $easy-grey;
        }
}

    @media screen and (max-width: 930px) {
        right: 1rem !important;
    }

    @include tablet {
        right: 10rem;
    }

    @include mobile {
        display: none !important;
    }
}

.map-background {
    @include mobile {
        display: none;
    }
}

.back-to {
    font-size: 12px;
    line-height: 20px;
    text-transform: uppercase;
    color: $grey;
    margin-top: 32px;

    @include mobile {
        border-right: 1px solid $lighter-grey;
        margin: 0;
        width: 30px;
    }

    svg {
        margin-right: 7px;
    }
}

.results,
.checkout {
    .results-title,
    .checkout-title {
        font-size: 32px;
        line-height: 35px;
        color: $dark-grey;
        margin-top: 8px;
        margin-bottom: 12px;

        @include mobile {
            font-size: 24px;
            padding: 0 20px;
        }
    }

    .results-recommended {
        margin-top: 12px;
        font-size: 12px;
        line-height: 170%;
        color: $violet;
        text-transform: uppercase;
    }

    .user-details {
        background: $white;
        padding: 32px;

        @include mobile {
            .ant-table-tbody > tr > td {
                padding: 6px;
            }
        }

        .additional-option {
            .name {
                font-size: 16px;
                line-height: 150%;
                color: $text;

                @include mobile {
                    font-size: 14px;
                }
            }

            .description {
                font-size: 12px;
                line-height: 170%;
                color: $light-steel;

                @include mobile {
                    font-size: 10px;
                }
            }
        }

        .price {
            font-size: 16px;
            line-height: 150%;
            color: $text;

            @include mobile {
                font-size: 14px;
            }
        }

        .added {
            font-size: 16px;
            line-height: 150%;
            color: $darker-grey;

            @include mobile {
                font-size: 14px;
            }
        }

        .actions-button,
        button.actions-button {
            justify-content: space-around;
            border: 2px solid $dark-grey;
            box-sizing: border-box;
            font-weight: 600;
            font-size: 13px;
            line-height: 16px;
            text-align: center;
            letter-spacing: 0.02em;
            text-transform: uppercase;
            color: $dark-grey;
            width: 80px;
            height: 40px;
            padding: 0;

            @include mobile {
                font-size: 10px;
            }

            &.light {
                border: 2px solid $easy-grey;
            }

            > span {
                cursor: pointer;
            }
        }
    }

    .details-and-summary {
        background: $dark-grey;
        height: fit-content;
        padding: 32px;

        .title {
            font-size: 24px;
            line-height: 150%;
            color: $white;
        }

        .label-description {
            display: flex;
            flex-direction: column;
            line-height: 150%;
            color: $white;
            margin-bottom: 11px;
            padding: 0;

            .label {
                opacity: 0.6;
            }
        }

        .price,
        .extra-price {
            border-top: solid 1px $darker-grey;
            justify-content: space-between;
            color: $white;
            opacity: .6;
            line-height: 150%;
            padding: 12px 0;
            align-items: center;
        }

        .total {
            font-size: 24px;
            border-top: solid 1px $darker-grey;
            justify-content: space-between;
            color: $white;
            padding: 14px 0;

            .return {
                font-size: 14px;
            }
        }

        .discount {
            font-size: 13px;
            color: $light-green;
        }
    }

    .payment {
        .title {
            font-size: 24px;
            line-height: 150%;
            color: $dark-grey;
        }

        .payment-group {
            > div {
                width: 100%;
            }

            .payment-radio {
                padding: 1rem !important;
            }

            .ant-radio-wrapper {
                display: flex;
                align-items: center;

                span:last-child {
                    width: 100%;
                }

                .ant-radio {
                    margin-right: 8px;

                    .ant-radio-inner,
                    > input {
                        border-color: $dark-grey;
                        width: 24px;
                        height: 24px;

                        &::after {
                            background-color: $dark-grey;
                            width: 16px;
                            height: 16px;
                        }
                    }
                }
            }

            .payment-method {
                font-size: 16px;
                line-height: 150%;
                color: $black;

                .subtitle {
                    font-size: 12px;
                    line-height: 170%;
                    color: $silver;
                    margin-left: 8px;
                }
            }

            .continue-to-pay {
                margin-top: 32px;
                width: 100%;
                height: 56px;
                background: $dark-grey;
                font-weight: 600;
                font-size: 15px;
                line-height: 18px;
                text-align: center;
                letter-spacing: 0.02em;
                text-transform: uppercase;
                color: $white;
                border: none;
                outline: none;
                box-shadow: none;
            }

            .checkout-payments {
                img {
                    margin-right: 8px;
                }
            }

            .paypal-container {
                > div {
                    width: 100%;
                    margin-top: 32px;
                }
            }
        }

        #promo-modal {
            @include mobile {
                flex-direction: column;
            }

            .ant-modal {
                width: 320px !important;
                height: 186px;

                .ant-modal-close-x {
                    outline: none !important;
                }

                .ant-modal-header {
                    display: none;
                }

                .ant-modal-body {
                    padding: 32px !important;

                    .additional-title {
                        font-size: 24px;
                        line-height: 150%;
                        color: $dark-grey;
                    }

                    input {
                        border: none;
                        outline: none !important;
                        background: $lighter-grey;
                        width: 160px;
                        font-size: 18px;
                        line-height: 150%;
                        text-align: center;

                        &::placeholder {
                            color: $grey;
                        }
                    }

                    button {
                        background: $dark-grey;
                        box-shadow: none;
                        border: none;
                        width: 56px;
                        height: 56px;
                        color: $white;
                        margin-top: 16px;
                        margin-left: 6px;
                    }
                }
            }

            .promo {
                font-size: 16px;
                line-height: 150%;
                text-align: right;
                color: $violet;
                cursor: pointer;

                svg {
                    margin: 0 5px;
                }
            }
        }
    }
}