@import '../../assets/styles/colors.scss';
@import '../../assets/styles/mixins.scss';

.user-profile {
    padding: 0 50px 0 112px;
    background: $light-silver;

    @include mobile {
        padding: 0 20px;
    }

    .hello-title {
        font-size: 32px;
        line-height: 35px;
        color: $dark-grey;
        margin-top: 32px;

        @include mobile {
            font-size: 24px;
        }
    }

    .user-tabs {
        .profile-details {
            .profile-card {
                padding: 30px;

                @include mobile {
                    padding: 10px;
                }

                .profile-header {
                    color: $dark-grey;
                    font-family: "Archivo";
                    font-style: normal;
                    font-weight: normal;
                    font-size: 24px;

                    @include mobile {
                        font-size: 16px;
                    }
                }

                .profile-info {
                    font-family: "Heebo";
                    font-style: normal;
                    font-weight: normal;
                    font-size: 16px;
                    color: $dark-grey;

                    @include mobile {
                        font-size: 14px;
                    }
                }

                .change-button {
                    background: $dark-grey;
                    height: 56px;
                    width: 100%;
                    border: none;
                    outline: none;
                    box-shadow: none;
                    color: $white;
                    text-transform: uppercase;
                    font-weight: 600;
                    font-size: 15px;
                    letter-spacing: 0.02em;
                    margin: 16px 0;

                    @include mobile {
                        font-size: 13px;
                    }
                }

                button:disabled,
                button[disabled] {
                    background: $white;
                    color: $dark-grey;
                    opacity: 0.4;
                    border: 2px solid #CFD4DC;
                }
            }
        }

        .ant-tabs {
            width: 100%;

            @include mobile {
                .ant-tabs-bar {
                    margin: 0;
                }
            }

            .ant-tabs-tab {
                padding: 24px 24px 7px 24px;
                font-size: 16px;
                line-height: 150%;
                text-align: center;
                color: $dark-grey;
                opacity: .6;

                @include mobile {
                    padding: 8px;
                    font-size: 11px;
                    margin: 0;
                }

                &.ant-tabs-tab-active {
                    opacity: 1;
                }
            }

            .ant-tabs-ink-bar {
                background-color: $dark-grey;
            }

            .ant-tabs-content {
                .my-orders {
                    min-height: 100px;

                    .ant-tooltip-inner {
                        width: 165px;
                    }

                    .ant-table-wrapper {
                        width: 100%;

                        .ant-table-container {
                            border-color: $light-silver;

                            .ant-table-thead {
                                th {
                                    background: $light-silver;
                                    font-size: 12px;
                                    line-height: 170%;
                                    text-transform: uppercase;
                                    color: $silver;
                                    border-color: $light-silver;

                                    @include mobile {
                                        &.ant-table-row-expand-icon-cell {
                                            border-right: none;
                                        }
                                    }

                                    .mobile-date {
                                        margin-left: -32px;
                                    }
                                }
                            }

                            .ant-table-tbody {
                                tr {
                                    background: $white;

                                    @include mobile {
                                        &.ant-table-expanded-row {
                                            td {
                                                border-bottom: 16px solid $light-silver;
                                                border-right: none;
                                                background: $white;
                                            }
                                        }
                                    }

                                    td {
                                        border-bottom: 16px solid $light-silver;
                                        border-color: $light-silver;

                                        @include mobile {
                                            border-bottom: 1px solid $light-silver;

                                            &.ant-table-row-expand-icon-cell {
                                                border-right: none;
                                            }

                                            .show-details {
                                                font-size: 16px;
                                                line-height: 150%;
                                                color: $dark-grey;
                                                margin-right: 8px;
                                            }
                                        }
                                    }

                                    .date {
                                        font-weight: bold;
                                        font-size: 16px;
                                        line-height: 150%;
                                        color: $dark-grey;

                                        @include mobile {
                                            font-size: 12px;
                                        }

                                        &.time {
                                            font-weight: normal;
                                        }
                                    }

                                    .mobile-date {
                                        margin-left: -32px;
                                    }

                                    .time-label,
                                    .date-label {
                                        font-weight: normal;
                                        font-size: 16px;
                                        line-height: 150%;
                                        color: $dark-grey;
                                        opacity: 0.6;

                                        @include mobile {
                                            font-size: 12px;
                                        }
                                    }

                                    .location-label {
                                        font-weight: normal;
                                        font-size: 16px;
                                        line-height: 150%;
                                        color: $dark-grey;
                                        opacity: 0.6;

                                        @include mobile {
                                            font-size: 14px;
                                        }
                                    }

                                    .order-value {
                                        font-weight: normal;
                                        font-size: 16px;
                                        line-height: 150%;
                                        color: $dark-grey;

                                        @include mobile {
                                            font-size: 14px;
                                        }

                                        img {
                                            margin-right: 15px;
                                        }
                                    }

                                    .payment {
                                        img.card {
                                            width: 48px;
                                            background: $white;
                                            border-radius: 4px;
                                        }

                                        .dollar {
                                            height: fit-content;
                                            color: $dark-grey;
                                            margin-right: 8px;
                                        }
                                    
                                        .price {
                                            font-size: 32px;
                                            color: $dark-grey;
                                    
                                            .decimal {
                                                font-size: 16px;
                                            }
                                        }
                                    }

                                    .actions {
                                        img {
                                            margin-bottom: 1rem;
                                            cursor: pointer;

                                            &.disabled {
                                                cursor: default !important;
                                            }
                                        }

                                        .user-trip-datails-button {
                                            background: inherit;
                                        }
                                    }

                                    &.upcoming {
                                        background: $dark-grey;

                                        td {
                                            background: $dark-grey !important;
                                            border-color: $darker-grey;
                                            border-bottom: 16px solid $light-silver;

                                            @include mobile {
                                                border-bottom: 1px solid $darker-grey;
                                            }
                                        }

                                        .time-label {
                                                background: $dark-violet;
                                                border-radius: 3px;
                                                font-weight: 500;
                                                font-size: 12px;
                                                line-height: 170%;
                                                text-align: center;
                                                color: $white;
                                                opacity: 1;
                                        }

                                        .date {
                                            color: $white;
                                        }

                                        .date-label {
                                            color: $white;
                                        }

                                        .location-label {
                                            color: $white;
                                        }
    
                                        .order-value {
                                            color: $white;
                                        }

                                        .payment {
                                            .dollar {
                                                color: $white;
                                            }
                                        
                                            .price {
                                                color: $white;
                                            }
                                        }
                                    }
                                }

                                @include mobile {
                                    tr.upcoming + tr.ant-table-expanded-row {
                                        td {
                                            border-bottom: 16px solid $light-silver;
                                            border-right: none;
                                            background: $dark-grey;

                                            .show-details {
                                                color: $light-silver;
                                                font-weight: 100;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@include mobileAndTablet {
    .trip-details {
        header {
            background: $light-black !important;

            img.go-back {
                transform: rotate(180deg);
            }

            .card-details {
                width: 120px;
                height: 120px;
                margin-top: 20px;
                background: $dark-violet;
                padding: 18px 24px;

                img.card {
                    width: 36px;
                    background: $white;
                    border-radius: 4px;
                }

                .price-block {
                    line-height: 24px;

                    .dollar {
                        height: fit-content;
                        color: $white;
                        margin-right: 8px;
                    }

                    .price {
                        font-size: 20px;
                        color: $white;

                        .decimal {
                            font-size: 12px;
                        }
                    }
                }

                .location-label {
                    color: $white;
                    opacity: 0.6;
                    font-size: 12px;
                    line-height: 150%;
                }
            }
        }

        main {
            background: $dark-grey;
            border-bottom: 1px solid $darker-grey;
            padding: 40px;
            overflow-y: auto;

            .date {
                font-weight: normal;
                font-size: 24px;
                line-height: 150%;
                color: $white;

                &.time {
                    font-size: 18px;
                    opacity: 0.6;
                    margin-left: 10px;
                }
            }

            .trip-label {
                font-size: 14px;
                line-height: 130%;
                color: $white;
                opacity: 0.6;
                margin-top: 16px;
            }

            .trip-value {
                font-size: 14px;
                line-height: 130%;
                color: $white;
            }

            .map-element {
                > div {
                    position: relative !important;

                    .gmnoprint {
                        display: none;
                    }
                }
            }
        }

        footer {
            background: $dark-grey;
        }
    }
}

.vip-user-label {
    background: $dark-violet;
    max-width: 130px;
    border-radius: 3px;
    font-weight: 500;
    font-size: 12px;
    line-height: 170%;
    text-align: center;
    color: $white;
    opacity: 1;
}