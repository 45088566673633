@import './colors.scss';
@import './mixins.scss';

@font-face {
    font-family: "Heebo";
    src : url("../fonts/Heebo-Regular.ttf");
}

@font-face {
    font-family: "Archivo";
    src : url("../fonts/Archivo-Regular.ttf");
}

::-webkit-scrollbar {
    width: 3px;
    height: 3px;
}

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 2px;
}
 
::-webkit-scrollbar-thumb {
    background: $darker-grey; 
    border-radius: 2px;
}

::-webkit-scrollbar-thumb:hover {
    background: $darker-grey; 
}

body {
    font-family: "Heebo";
}

.row-with-widget {
    padding-bottom: 150px;
}

.display-none {
    display: none;
}

.social-links {
    img {
        color: #393644;
    }
}

.tripAdvisorWidget {
    display: block !important;
    position: absolute;
    bottom: 0;
    right: 0;

    #CDSWIDEXC {
        position: absolute;
        bottom: 0;
        right: 0;
    }

    img {
        max-width: 200px;
        max-height: 200px;
    }

    @include mobile {
        display: none !important;
    }
}

.tripAdvisorWidget-content {
    display: block !important;
    position: absolute;
    right: 0;

    #CDSWIDEXC {
        position: absolute;
        bottom: 0;
        right: 0;
    }

    img {
        max-width: 200px;
        max-height: 200px;
    }

    @include mobile {
        display: none !important;
    }
}

.tripAdvisorWidget-checkout {
    display: block !important;
    position: relative;
    
    #CDSWIDEXC {
        position: absolute;
        bottom: 0;
        right: -1.5rem;
    }

    img {
        max-width: 200px;
        max-height: 200px;
    }
}

.main-layout {
    background-image: url('../icons/background.jpg');
    background-repeat: no-repeat;

    @include mobile {
        background: $white;
        width: 100%;
    }

    &.without-background-image {
        background: $light-silver !important;
        main {
            min-height: calc(100vh - 64px);
            
            .background-full-height {
                background: $light-silver !important;
                .content-padding {
                    padding: 0 50px;

                    @include mobile {
                        padding: 0;
                    }
                }
        
                @include desktop {
                    padding-left: 112px !important;
                }
                @include tablet {
                    padding: 20px 20px !important;
                }
                @include mobile {
                    padding: 20px 20px !important;
                }
            }

            .page-title {
                font-size: 32px;
                line-height: 35px;
                color: $dark-grey;
            }

            .page-content {
                .card-item {
                    @include tablet {
                        padding: 24px 24px 0 0 !important; 
                    }

                    @include mobile {
                        padding: 24px 24px 0 0 !important; 
                    }

                    .card-body {
                        font-size: 16px;
                        line-height: 180%;
                        color: $darker-grey;
                        padding: 32px;
                        min-height: 200px;
                    }
                }
            }

            .page-content-about {
                justify-content: start;
                
                .main-img {
                    @include mobile {
                        min-height: 250px;
                        min-width: 250px;
                    }
                }

                .main-info {
                    @include mobile-small {
                        padding-bottom: 7rem !important;
                    }
                }

                .card-item {
                    position: absolute;

                    .card-body {
                        color: $white !important;
                        background-color: $dark-grey !important;
                        @include desktop {
                            padding: 40px; 
                            min-height: 200px;
                        }
                    }

                    @include mobile {
                        right: 0;
                        top: 100px;
                    }

                    @include tablet {
                        right: 0;
                    }

                    @include desktop {
                        left: 6rem;
                    }
                    
                }

                .img-top {
                    z-index: 99999;
                }

                .footer-card {
                    .footer-card-header {
                        padding-top: 50px;
                        padding-bottom: 10px;
                    }

                    @media screen and (min-width: 992px) {
                        right: 220px;
                    }

                    @media screen and (min-width: 1448px) {
                        bottom: 50px;
                        right: 220px;
                    }
                }
                
                .additional-info {
                    .ant-carousel .slick-slide {
                        text-align: center;
                        display: block;
                        overflow: hidden;
                    }

                    .ant-carousel .slick-dots li button {
                        background: $dark-grey;
                    }

                    .footer-text {
                        font-family: "Archivo", sans-serif;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 32px;
                        color: $dark-grey;
                    }

                    @include mobileAndTablet {
                        padding: 24px 24px 0 0 !important; 
                    }

                    @include desktop {
                        padding: 0 0 0 100px !important;
                    }
                }
            }

            .page-content-privacy-terms {
                h4 {
                    padding: 10px 0;
                }

                .table {
                    padding-bottom: 15px;
                    font-family: "Heebo";
                    font-style: normal;
                    font-size: 16px !important;
                    color: $darker-grey;
                }

                .nav-tabs .nav-link {
                    opacity: 0.6;
                    color: $dark-grey !important;
                    border: none;
                }
                .nav-tabs .nav-link.active {
                    background-color: inherit;
                    opacity: 1;
                    color: $dark-grey !important;
                }

                .ant-tabs-tab-btn {
                    font-size: 16px;
                    color: $dark-grey !important;
                }

                .sub-text-terms {
                    font-family: "Heebo";
                    font-style: normal;
                    font-weight: normal;
                    font-size: 24px;
                    color: $darker-grey;
                }
            }            

            .page-content-faq {
                .card-header {
                    padding: 0;
                    border: none;
                    background: $dark-grey;
                    font-family: "Heebo";
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;

                    .btn {
                        padding: 20px;
                        text-align: start;
                        background: $white;
                        color: $dark-grey;
                        border: none;
                    }

                    .btn.collapsed {
                        color: #D9D9DC;
                        background: $dark-grey;
                    }

                    .btn:focus {
                        box-shadow: unset;
                    }
                }

                .card-body {
                    color: $darker-grey;
                    font-family: "Heebo";
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                }
            }

            @include mobile {
                .main-text {
                    font-family: "Archivo", sans-serif;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 18px;
                }

                .sub-text {
                    font-family: "Heebo";
                    font-style: normal;
                    font-weight: normal;
                    font-size: 14px;
                    color: $darker-grey;
                }
            }

            @include tablet {
                .main-text {
                    font-family: "Archivo", sans-serif;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 24px;
                }

                .sub-text {
                    font-family: "Heebo";
                    font-style: normal;
                    font-weight: normal;
                    font-size: 16px;
                    color: $darker-grey;
                }
            }

            @include desktop {
                .main-text {
                    font-family: "Archivo", sans-serif;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 24px;
                }

                .sub-text {
                    font-family: "Heebo";
                    font-style: normal;
                    font-weight: normal;
                    font-size: 16px;
                    color: $darker-grey;
                }
            }
        }
    }
}

.progress-layout {
    background: $light-silver;

    main {
        padding: 0 80px;
        overflow-y: auto;
    }

    header {
        padding: 0 80px !important;
    }
}

.admin-layout {
    height: 100vh;

    header.admin-header {
        padding: 0 !important;
        margin-right: 24px;

        .menu-button {
            padding: 24px;
        }

        .page-title {
            text-transform: uppercase;
            font-weight: 600;
        }
    }

    .ant-layout-sider {
        background: $dark-grey;

        .logo {
            display: flex;
            align-items: center;
            justify-content: flex-start;

            img {
                padding: 24px;
            }
        }

        .ant-menu {
            background: $dark-grey;

            li {
                font-weight: 500;
                font-size: 14px;
                letter-spacing: 0.02em;
                margin: 0;
                height: 48px;
                line-height: 48px;
                display: flex;
                align-items: center;

                a {
                    color: $easy-grey;
                    text-decoration: none;
                }

                .anticon svg {
                    color: $easy-grey;
                }

                &::after {
                    border-right: 3px solid $white;
                }

                &.ant-menu-item-selected {
                    background: $light-black;

                    a {
                        color: $white;
                        text-decoration: none;
                    }

                    .anticon svg {
                        color: $white;
                    }
                    
                    &::after {
                        border-right: 3px solid $violet;
                    }
                }
            }
        }

    }
    
    .ant-layout-sider-collapsed {
        .ant-menu-inline-collapsed {
            li {
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }

    main {
        margin: 24px;

        .admin-page {
            .export-import {
                border: 2px solid $silver;
                padding: 12px 24px;
                margin-right: 24px;
                min-width: 170px;
                font-weight: 600;
                font-size: 13px;
                line-height: 16px;
                text-align: center;
                letter-spacing: 0.02em;
                text-transform: uppercase;
                color: $dark-grey;

                a {
                    color: $dark-grey;
                    text-decoration: none;
                }
            }

            .template {
                border: none;
                background: none;
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 160px;
                font-weight: 600;
                font-size: 14px;
                color: $dark-violet;
                outline: none;

                svg {
                    color: $dark-violet;
                }
            }

            .admin-table {
                overflow-x: auto;

                .ant-table-wrapper {
                    width: auto;

                    .ant-checkbox-wrapper:hover 
                    .ant-checkbox-inner, 
                    .ant-checkbox:hover 
                    .ant-checkbox-inner, 
                    .ant-checkbox-input:focus + .ant-checkbox-inner {
                        border-color: $black !important;
                    }

                    .ant-checkbox-checked .ant-checkbox-inner {
                        background-color: inherit;
                        border-color: $black;
                    }

                    .ant-checkbox-checked .ant-checkbox-inner::after {
                        border-color: $black !important;
                    }
                    .ant-checkbox-checked::after {
                        border-color: $black !important;
                    }

                    tr {
                        th {
                            max-width: 110px;
                            color: $silver;

                            .title-header {
                                font-weight: 500;
                            }
                        }

                        td {
                            background: $white;
                            max-width: 110px;
                            font-weight: 500;
                            color: $light-steel;

                            .lighter {
                                color: $easy-grey;
                            }

                            .succeeded {
                                color: #6bbd80;
                                font-weight: 600;
                            }
                            
                            .canceled {
                                color: #f3d789;
                                font-weight: 600;
                            }
                            
                            .failed {
                                color: #ff4d4f;
                                font-weight: 600;
                            }
                            
                            .incompleted {
                                color: #959CAC;
                                font-weight: 600;
                            }
                        }
                    }
                }
            }

            .admin-validation {
                .ant-collapse-header {
                    color: $red;
                    font-weight: 600;
                }

                .ant-collapse-content {
                    color: $red;
                    max-height: 250px;
                    overflow-y: auto;
                    overflow-x: auto;

                    .error-item {
                        .anticon {
                            margin: 0 10px;
                        }
                    }
                }
            }

            .ant-select-selector {
                height: 48px;
                display: flex;
                align-items: center;
                border: none;
                color: $grey;
            }

            .ant-input-search {
                height: 48px;
                border: none;
                color: $grey;
            }

            .dispatch-header {
                @media screen and (max-width: 1590px) {
                    flex-direction: column;
                    
                    .main-filters {
                        justify-content: start !important;
                    }                   

                    .statuses {
                        padding-top: 10px;
                        width: 420px;
                    }
                }
            }

            .date-button {
                height: 48px;
                min-width: 120px;
                border: solid 2px $darker-grey;
                margin: 0 10px;
                color: $grey;
                text-transform: uppercase;
            }

            .statuses {
                min-width: 420px;
                font-size: 14px;
                color: $grey;

                .col {
                    min-width: 130px;
                }
            }
        }
    }
}

.dot {
    height: 10px;
    width: 10px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
}

header {
    background: transparent !important;
    z-index: 10;

    @include mobile {
        padding: 0 20px !important;

        .hamburger-menu {
            > a,
            > div {
                z-index: 12;
            }
        }

        .opened-menu {
            background: $light-silver;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 10;
            padding: 20px;

            > a {
                font-size: 24px;
                line-height: 150%;
                color: $dark-grey;
                opacity: 0.8;
                margin-bottom: 32px;
            }

            .mobile-logout {
                button {
                    height: 48px;
                    width: 170px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
    }

    @include desktop {
        padding-left: 112px !important;
    }

    ul.ant-menu {
      background: transparent !important;

        li.ant-menu-item {
            background: transparent !important;

            a {
                font-weight: normal;
                color: $header-text;

                &:hover {
                    color: $header-text-hovered !important;
                    text-decoration: none;
                }
            }

            &.ant-menu-item-selected {
                a {
                    color: $dark-grey;
                }
            }
        }
    }

    .user-logo-image {
        img {
            height: 34px;
            width: 34px;
            border-radius: 20px;
            background: $header-text;
        }

        span {
            margin: 16px;
            color: $dark-grey;

            @include mobile {
                margin: 10px;
            }
        }
    }

    .mobile-user-name {
        text-overflow: ellipsis !important;
        white-space: nowrap !important;
        overflow: hidden !important
    }

    .user-login {
        button {
            border: solid 2px $light-grey;
            background: $white;

            span {
                font-weight: 600;
                font-size: 15px;
                line-height: 18px;
                display: flex;
                align-items: center;
                text-align: center;
                letter-spacing: 0.02em;
                text-transform: uppercase;
                color: $dark-grey;
                padding: 0 10px;
            }
        }
    }
}

.ant-pagination-item-active {
    border-color: #d9d9d9;
    background: $violet;
    color: $white;
}

.ant-pagination-item-link {
    display: flex !important;
    align-items: center;
    justify-content: center;
}

.ant-notification-notice-with-icon 
.ant-notification-notice-description {
    margin: 0;
}

.ant-notification-notice-with-icon
.ant-notification-notice-message {
    max-width: 270px;
    max-height: 500px;
    overflow-y: auto;
    overflow-x: hidden;
}

.ant-collapse,
.ant-collapse-item,
.ant-collapse-content {
    border: none !important;
}

.ant-tooltip {
    max-width: 770px;
}

.ant-table-column-sorter-up.active,
.ant-table-column-sorter-down.active {
    color: $violet;
}

.ant-tooltip-inner {
    background: $light-black;
}

.toggle-with-label {
    font-size: 16px;
    line-height: 150%;
    color: $dark-grey;
    height: 36px;
    display: flex;
    align-items: center;

    button {
        border-radius: 24px;
        transform: matrix(1, 0, 0, -1, 0, 0);

        &.ant-switch {
            background: $light-grey;

            &::after {
                background: $white;
                box-shadow: 4px 2px 8px $grey-shadow;
                border-radius: 24px;
                transform: matrix(1, 0, 0, -1, 0, 0);
            }
        }

        &.ant-switch-checked {
            background: $violet !important;

            &::after {
                background: $white;
                box-shadow: 4px 2px 8px $grey-shadow;
                border-radius: 24px;
                transform: matrix(1, 0, 0, -1, 0, 0);
                margin-left: -19px;
            }
        }
    }

    span {
        margin-left: 12px;
    }
}

.submit-button {
    margin: 24px 0;

    @include mobile {
        margin: 0;
    }

    button,
    button:hover,
    button:focus {
        width: 100%;
        height: 56px;
        background: $dark-grey;

        &:disabled {
            opacity: .5;
            background: $dark-grey;
        }

        span {
            font-style: normal;
            font-weight: 600;
            font-size: 15px;
            line-height: 18px;
            text-align: center;
            letter-spacing: 0.02em;
            text-transform: uppercase;
            color: $white;
        }
    }
}

.disabled {
    cursor: default !important;
    color: $darker-grey;
}

.location-input {
    margin-top: 16px;

    .filled, .empty {
        border: none;
        min-height: 56px;
        background: $lighter-grey;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 16px;
        font-size: 16px;
        color: $grey;

        .filledValue {
            color: $dark-grey;
            opacity: 1;
            font-size: 16px;
        }

        .placeholderValue {
            font-size: 16px;
            color: $grey;
        }

        span {
            width: 100%;
            min-height: 20px;
            font-size: 12px;
            color: $grey;
            opacity: 0.8;
        }

        img {
            height: 10px;
            width: 10px;
            cursor: pointer;
            margin: 7px;
        }
    }

    .empty.required {
        border: solid 1px $red;

        .placeholderValue {
            color: $light-red;
        }
    }

    .active {
        height: 56px;
        padding: 0 8px;
        background: $lighter-grey;

        input {
            height: 24px;
            padding-bottom: 0px !important;
            width: 100%;
            box-shadow: none;
            border: none;
            outline: none;
            background: $lighter-grey;
        }

        span {
            height: 24px;
            font-size: 12px;
            color: $grey;
            opacity: 0.8;
        }
    }

    .ant-dropdown {
        width: 378px;
    }
}

.ant-radio-group {
    font-size: inherit !important;
    color: inherit !important;
}

.user-input {
    margin-top: 16px;

    .active {
        height: 56px;
        padding: 0 8px;
        background: $lighter-grey;

        input {
            height: 24px;
            width: 100%;
            box-shadow: none;
            border: none;
            outline: none;
            background: $lighter-grey;
        }

        div {
            height: 20px;
            font-size: 12px;
            color: $grey;
            opacity: 0.8;
        }
    }

    .ant-dropdown {
        width: 378px;
    }
}

.location-date-time {
    transition-timing-function: linear;
    
    .ant-dropdown {
        width: 118px;
        height: 256px;
    }

    .day-tile, .time-tile {
        height: 56px;
        width: 64px;
        color: $grey;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background: $lighter-grey;
        margin-right: 2px;
        cursor: pointer;

        .ant-picker, .ant-select {
            border: none;
            background: none;
            box-shadow: none;

            input {
                display: none;
            }
        }

        &.selected {
            color: $dark-grey;

            &.invalid {
                color: $red;
            }
        }

        &.invalid {
            color: $light-red;
        }

        span {
            &:first-child {
                font-size: 18px;
            }

            &:last-child {
                font-size: 12px;
            }
        }
    }

    .time-tile {
        width: 118px;
        margin-right: 0;
        margin-left: 14px;

        span {
            &:first-child {
                font-size: 12px;
            }

            &:last-child {
                font-size: 16px;
                color: $dark-grey;
            }
        }

        .time-img {
            height: fit-content;
            margin: 10px;
        }
    }
}

.ant-dropdown {
    ul {
        height: inherit;
        width: inherit;
        overflow: auto;

        li {
            font-size: 16px;
            line-height: 150%;
            background: $lighter-grey;
            color: $dark-grey;
            opacity: 0.6;

            &.selected,
            &.ant-dropdown-menu-item-active {
                background: $white;
                opacity: 1;
            }
        }
    }
}

.progress-pages {
    .steps {
        height: 40px;

        .step {
            font-size: 16px;
            color: $dark-grey;
            margin: 0 24px;

            &.prev-step {
                opacity: 0.6;
            }
        
            &.next-step {
                opacity: 0.2;
            }
        }
    }

    .ant-progress-inner {
        height: 2px;
        background: $easy-grey;

        .ant-progress-bg {
            background: $dark-grey;
            height: 2px !important;
        }
    }
}

.car-tile {
    min-height: 160px;
    background: $white;
    border-radius: 2px;
    padding: 24px;

    @include mobile {
        padding: 0;
    }

    img.car {
        width: 167px;
        max-height: 120px;

        @include mobile {
            width: 130px;
        }
    }

    .extra-price {
        font-size: 12px;
        line-height: 170%;
        color: $silver;
        padding-left: 0;
        align-self: flex-start;
    }

    button {
        height: 48px;
        width: 114px;
        background: $white;
        border: 2px solid $dark-grey;
        box-sizing: border-box;
        border-radius: 0;
        text-transform: uppercase;
        color: $dark-grey;
        font-weight: 600;

        &:active,
        &:hover,
        &:focus {
            color: $silver;
            border-color: $silver;
        }
    }

    .dollar {
        height: fit-content;
        color: $dark-grey;
        margin-right: 8px;
    }

    .price {
        font-size: 32px;
        color: $dark-grey;

        .decimal {
            font-size: 16px;
        }
    }

    .options {
        font-size: 12px;
        line-height: 170%;
        color: $silver;
        padding-left: 0;

        li {
            list-style-type: none;

            &::before {
                content: '∙';
                margin: 0 6px;
            }
        }
    }

    .baggage-passengers {
        span {
            margin: 0 25px 0 8px;
            color: $silver;
            font-size: 12px;
        }
    }

    .title {
        font-size: 18px;
        color: $dark-grey;
    }

    .car-type {
        font-weight: 500;
        font-size: 12px;
        line-height: 170%;
        text-align: center;
        color: $dark-violet;
        opacity: 0.8;
        flex: none;
        order: 0;
        align-self: center;
        padding: 0 10px;
        background: $light-violet;
        border-radius: 3px;
        align-self: flex-start;
    }

    &.recommended {
        background: $dark-grey;

        .dollar {
            color: $white;
        }

        .price {
            color: $white;
        }

        .options {
            color: $white;
        }

        .baggage-passengers {
            span {
                color: $white;
            }
        }

        .car-type {
            background: $steel;
            color: #FFFFFF;
        }

        .title {
            color: $white;
        }
    }
}

.payment-title {
    font-size: 48px;
    line-height: 52px;
    letter-spacing: 0.02em;
    color: $text;
}

.payment-radio {
    min-height: 88px;
    background: $white;

    .child {
        > div {
            width: 100%;
            margin-top: 32px;
        }
    }
}

.checkout {
    form,
    .invoice-form {
        label {
            width: 24%;
            margin-top: 32px;
            background: $lighter-grey;
            padding: 18px;
            margin-left: 2%;
            font-size: 16px;
            color: $grey;

            &:first-child {
                width: 48%;
                margin-left: 0;
            }
        }

        button {
            background: $dark-grey;
            height: 56px;
            width: 100%;
            border: none;
            outline: none;
            box-shadow: none;
            color: $white;
            text-transform: uppercase;
            font-weight: 600;
            font-size: 15px;
            letter-spacing: 0.02em;
            margin: 16px 0;
        
        }
    }
}

@include mobile {
    .ant-modal {
        max-width: 100% !important;
        vertical-align: bottom !important;
        margin: 0;
        padding: 0;
    }
}

.ant-notification-notice-with-icon {
    display: flex;
}

#route-not-found {
    .logined {
        .submit-button {
            width: 56px !important;
        }
    }
}

#success-modal,
#cancel-modal,
#success-confirm,
#route-not-found,
#login,
#signin,
#driver {
    .ant-modal-header,
    .ant-modal-close {
        display: none;
    }

    .ant-modal-body {
        padding: 48px;

        @include mobile {
            padding: 24px;

            .ant-tabs-tab {
                font-size: 16px;
            }
        }

        .payment-title {
            font-size: 24px;
            line-height: 150%;
            text-align: center;
            color: $text;
        }

        .payment-subtitle {
            font-size: 18px;
            line-height: 150%;
            text-align: center;
            color: $darker-grey;
        }

        .payment-button {
            height: 56px;
            width: 138px;
            border-radius: 2px;
            outline: none;
            box-shadow: none;
            border: solid 2px $easy-grey;
            font-weight: 600;
            font-size: 15px;
            line-height: 18px;
            letter-spacing: 0.02em;
            text-transform: uppercase;
            color: $dark-grey;

            &.return {
                width: 200px;
            }

            a {
                text-decoration: none;
            }
        }

        .submit-button {
            margin-top: 16px;
            background: $dark-grey;
            box-shadow: none;
            border: none;
            width: 56px;
            height: 56px;
            color: $white;
        }

        .password {
            font-size: 12px;
            color: $grey;
        }

        .forgot {
            font-weight: bold;
            font-size: 12px;
            line-height: 15px;
            letter-spacing: 0.02em;
            text-transform: uppercase;
            color: $dark-grey;
            opacity: 1;
            align-items: center;
            text-align: right;
            cursor: pointer;
        }

        .do-not-have-account {
            display: flex;
            justify-content: center;
            align-items: center;

            span {
                font-size: 16px;
                line-height: 150%;
                color: $darker-grey;
                margin-right: 10px;
            }

            a {
                font-weight: bold;
                font-size: 15px;
                line-height: 18px;
                letter-spacing: 0.02em;
                text-transform: uppercase;
                color: $dark-grey;
                text-decoration: underline;
            }
        }
    }
}

#route-not-found,
#login,
#signin,
#driver {
    .ant-modal-close {
        display: block;

        img {
            height: 12px;
        }

        &:focus {
            outline: none;
        }
    }
}

.ant-btn-primary {
    background: $dark-violet;
    border-color: $dark-violet;

    &:active,
    &:hover,
    &:focus {
        background: $violet;
        border-color: $violet;
    }
}

.vip-label {
    background: $violet;
    width: 30px;
    border-radius: 8px;
    font-weight: 500;
    font-size: 12px;
    line-height: 170%;
    text-align: center;
    color: $white;
    opacity: 1;
}

.ant-tabs {
    width: 100%;

    @include mobile {
        .ant-tabs-bar {
            margin: 0;
        }
    }

    .ant-tabs-tab {
        padding: 24px 24px 7px 24px;
        font-size: 16px;
        line-height: 150%;
        text-align: center;
        color: $dark-grey !important;
        opacity: .6;

        .ant-tabs-tab-btn {
            color: $dark-grey !important;
        }

        @include mobile {
            padding: 8px;
            font-size: 11px;
            margin: 0;
        }

        &:hover {
            color: $dark-grey;
        }

        &.ant-tabs-tab-active {
            opacity: 1;
        }
    }

    .ant-tabs-ink-bar {
        background-color: $dark-grey;
    }

    .ant-tabs-content {

        .ant-table-wrapper {
            width: 100%;

            .ant-table-container {
                border-color: $light-silver;

                .ant-table-thead {
                    th {
                        background: $light-silver;
                        font-size: 12px;
                        line-height: 170%;
                        text-transform: uppercase;
                        color: $silver;
                        border-color: $light-silver;

                        @include mobile {
                            &.ant-table-row-expand-icon-cell {
                                border-right: none;
                            }
                        }
                    }
                }

                .ant-table-tbody {
                    tr {
                        background: $white;

                        @include mobile {
                            &.ant-table-expanded-row {
                                td {
                                    border-bottom: 16px solid $light-silver;
                                    border-right: none;
                                    background: $white;
                                }
                            }
                        }

                        td {
                            border-bottom: 16px solid $light-silver;
                            border-color: $light-silver;

                            @include mobile {
                                border-bottom: 1px solid $light-silver;

                                &.ant-table-row-expand-icon-cell {
                                    border-right: none;
                                    }
                                }
                            }
                        }
                    }
                }
        }
    }
}