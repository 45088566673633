$header-text: rgba(57, 54, 68, 0.8);
$header-text-hovered: rgba(57, 54, 68, 0.4);

$black: #000;
$light-black: #2B2934;
$white: #fff;
$violet: #605DF3;
$light-violet: #E8E6FF;
$dark-violet: #4E31B5;
$grey: #959CAC;
$light-steel: #6B7377;
$steel: rgba(255, 255, 255, 0.09);
$light-silver: #E5E5E5;
$silver: #7D7A83;
$easy-grey: #BFC2C9;
$light-grey: #ECEEF1;
$lighter-grey: #F6F6F8;
$dark-grey: #393644;
$darker-grey: #5C5964;
$red: #ff4d4f;
$light-red: #e69fa0;
$light-green: #9fe6c9;

$text: #252525;
$grey-shadow: rgba(57, 54, 68, 0.25);
